
// Content styles
.block-text,
.biography {
	line-height: 1.7;
	
	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		&.intro {
			padding: $paragraph-margin 0;
			border-bottom: $border;
			border-top: $border;
			color: $color-sub;
			font-family: $font-family-sub;
			font-size: $h5;
			
			@media #{$mobileXL} {
				font-size: $h4;
			}
		}
	}
	
	// New default <ul> lists
	ul {
		@extend %default-ul;
	}
	
	// New default <ol> lists
	ol {
		@extend %default-ol;
	}
}

