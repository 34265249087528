// related items list

.section-library {

	.main {

		.centre {

			.related-items {
				border: none;

				.items {
					.item {
						border-bottom: $border;
						padding-bottom: $site-padding + 0.5rem;
						margin-bottom: $block-margin - 1rem;

						&:last-child {
							border: none;
						}

						.related-title {
							display: block;
							font-size: $font-size-body + 0.3rem;
							margin-bottom: $block-padding;
						}
					}
				}
			}

		}
	}

}

