
// Clear paragraph styles
p {
	margin: 0;
}

// Legal Library styles
.section-library {

	p {
		margin-bottom: $paragraph-margin;
		line-height: 1.7;
	}
}

// Reset strong tags
strong {
	font-weight: $font-bold;
}

// Reset hr tags
hr {
	border: $border;
}
blockquote {
	border-left: 5px solid $border-color;
	margin: 0 0 ($block-margin / 2);
	padding-left: $site-padding;
}
